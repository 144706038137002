<template>
<div>
  <div class="metabase-loader" v-show="loaded == false">
     <div class="metabase-spinner">
        <atom-spinner
          :animation-duration="1000"
          :size="100"
          color="#1BC5BD"
        />
    </div>
  </div>


  <div class="frame">
    <div class="frame-inner">
      <iframe
        :src="url"
        frameborder="0"
        border="0"
        width="100%"
        allowTransparency
        scrolling="yes"
        titled="false"
        @load="load"
        v-show="loaded"
        style="background: transparent"
      ></iframe>
    </div>
  </div>


</div>
</template>

<script>
import { AtomSpinner } from 'epic-spinners'
export default {
  name: "metabase",

  components: {
    AtomSpinner
  },


  data() {
    return {
      loaded: false,
    };
  },
  props: {
    url: { default: null }
  },

  methods: {

    load() {
      let self = this
      setTimeout(function () {
      	self.loaded = true;
      }, 5000);
    }

  }

};
</script>

<style scoped>
.frame {
  margin: -0px 0px 0px 0px; /* -30 */
  overflow: hidden;
  height: 100vh;
  border-radius: 12px 0 0 12px;
}

.frame-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vh; /*window size so its doenst scroll*/
  margin: 0px -10px 0 0;
  border-top:1px;
}

.frame-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px -10px 0 0;
  width: 100%;
  height: 91vh;
}

.metabase-loader{
  background-image:transparent;
  height: 90vh;
  display: grid;
}

.metabase-spinner{
  margin: auto;
}


</style>
